import type { Query, QueryFastCaptureSearchListV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { FAST_CAPTURE_SEARCH_LIST_V2 } from './gql/fastCaptureSearchListV2';

type QueryDataType = Pick<Query, 'fastCaptureSearchListV2'>;
type OutgoingDataType = QueryDataType['fastCaptureSearchListV2'];

type IncomingDataType = {
    ids: Array<string>;
    isSupplierProductNumber: boolean;
    skip?: boolean;
};

const onError = getErrorLoggerFunction('fastCaptureSearchListV2');

const MINIMUM_IDS_LENGTH = 1;

export const useFastCaptureSearchListV2 = (props: IncomingDataType) => {
    const { ids, isSupplierProductNumber = false, skip } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skipValue = skip || ids.length >= MINIMUM_IDS_LENGTH;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryFastCaptureSearchListV2Args>(
        FAST_CAPTURE_SEARCH_LIST_V2,
        {
            onError,
            skip: skipValue,
            variables: { ids, isSupplierProductNumber }
        }
    );

    useEffect(() => {
        if (data?.fastCaptureSearchListV2) {
            setOutgoingData(data.fastCaptureSearchListV2);
        }
    }, [data]);

    return {
        fastCaptureSearchListV2: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
