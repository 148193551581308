import styled from 'styled-components';

export const StyledImage = styled.div`
    position: relative;
    height: 100%;

    .ab-image {
        width: 100%;
        height: 100%;
        &.ab-image-object-fit-cover {
            object-fit: cover;
        }

        &.ab-image-object-fit-contain {
            object-fit: contain;
        }

        &.ab-image-object-fit-fill {
            object-fit: fill;
        }

        &.ab-image-object-fit-none {
            object-fit: none;
        }

        &.ab-image-object-fit-scale-down {
            object-fit: scale-down;
        }
    }
`;
