import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SHOPPING_LIST_V2 } from './gql/shoppingListV2';

type QueryDataType = Pick<Query, 'shoppingListV2'>;
type OutgoingDataType = QueryDataType['shoppingListV2'];

const onError = getErrorLoggerFunction('shoppingListV2');

export const useShoppingListV2 = (shoppingListV2Id: string, skip = false) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(SHOPPING_LIST_V2, {
        variables: { shoppingListV2Id },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.shoppingListV2);
        }
    }, [data]);

    return {
        shoppingList: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
