import type { Mutation, MutationCreateMyRewardOrderArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_ORDER_REWARD } from './gql/createOrderReward';

type MutationDataType = Pick<Mutation, 'createMyRewardOrder'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createMyRewardOrder');

export const useOrderReward = (cartId: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createOrderReward, { client, loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateMyRewardOrderArgs
    >(CREATE_ORDER_REWARD, {
        variables: { cartId },
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createOrderReward,
        data,
        client,
        loading,
        response
    };
};
