import { useAuth } from '@ab-core/hooks';
import { Search } from '@ab-core/search';
import type { FC } from 'react';
import React from 'react';
import { Brand } from '../components/brand';
import { Cart } from '../components/cart';
import { MobileBarWrapper } from './index.styled';

type MobileProps = { searchString?: string; pathname: string };

export const Mobile: FC<MobileProps> = (props) => {
    const { searchString, pathname } = props;
    const { user } = useAuth();

    return (
        <MobileBarWrapper>
            <Brand />
            <Search searchString={searchString} />
            {user && <Cart pathname={pathname} />}
        </MobileBarWrapper>
    );
};
