import { gql } from '@apollo/client';
import { FRAGMENTS } from './../../_fragments';

export const SHOPPING_LIST_V2 = gql`
    query ShoppingListV2($shoppingListV2Id: String!) {
        shoppingListV2(id: $shoppingListV2Id) {
            id
            lineItemsCount
            name
            lastModifiedAt
            slug
            shareState
            createdBy {
                contactId
                name
            }
            lineItems {
                ...ABLineItemFields
            }
        }
    }
    ${FRAGMENTS.LINE_ITEMS_DATA}
`;
