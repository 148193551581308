import { userHasPermissions } from '@ab-core-functions/user-permission';
import { ROUTES, logIn, useIsFeatureVisible, userIsLocked } from '@ab-core/functions';
import { useAuth } from '@ab-core/hooks';
import { navigate } from 'gatsby';
import type React from 'react';
import type { FC } from 'react';

type ProtectedRouteProps = {
    children: React.ReactElement | null;
    redirectAfterLogin?: boolean;
    featureName?: string;
    requiredPermission?: string;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
    const { children, redirectAfterLogin, featureName = '', requiredPermission = '' } = props;
    const { user, userLoading } = useAuth();
    const { isFeatureVisible } = useIsFeatureVisible();

    const userIsPermitted = userHasPermissions(requiredPermission, true);
    const locked = userIsLocked();

    if (locked) {
        localStorage.removeItem('isLoggedIn');
        navigate('/');
    }

    if (!user && !userLoading) {
        logIn(redirectAfterLogin);

        return null;
    }

    if (isFeatureVisible(featureName, true) && userIsPermitted) {
        return children;
    }

    navigate(ROUTES.SHOP);

    return null;
};

type FeatureRouteProps = {
    children: React.ReactElement | null;
    featureName?: string;
};

export const FeatureRoute: FC<FeatureRouteProps> = (props) => {
    const { children, featureName = '' } = props;
    const { isFeatureVisible } = useIsFeatureVisible();

    if (isFeatureVisible(featureName, true)) {
        return children;
    }

    navigate(ROUTES.SHOP);

    return null;
};
