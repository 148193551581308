import { gql } from '@apollo/client';

export const CART_OBJECT = gql`
    fragment ABCartFields on Cart {
        id
        version
        cartState
        lastModifiedAt
        custom {
            customFieldsRaw {
                name
                value
            }
        }
        shippingAddress {
            id
            externalId
            primaryAddress
            name
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
            streetName
            postalCode
            city
            country
            defaultShippingInstructions
        }
        shippingInfo {
            shippingMethod {
                id
                key
            }
        }
        name
        isActiveCart
        orderText
        costCenter
        singleInv
        priceDN
        sentFrom
        deliveryDate
        completeDelivery
        shippingInstructions
        displayShippingMethod
        cartProjectNr
        shareState
        createdBy {
            contactId
            name
            email
        }
        lineItemsCount
        customLineItems {
            ean
            id
            name
            positionText
            addedBy {
                contactId
                name
            }
            quantity
            quantityUnit
            supplierName
            supplierProductNumber
        }
        lineItemsV2 {
            bonus {
                isTecselect
                isAbakus
                isAbakusPlus
                isPromotion
                isTopProduct
                isVipProduct
                isPremiumProduct
            }
            categories {
                id
                externalId
                slug
                name
                description
                stagedProductCount
                orderHint
                parent {
                    id
                }
                custom {
                    customFieldsRaw {
                        name
                        value
                    }
                }
                metaTitle
                metaDescription
            }
            customerArticleNumber
            ean
            hasAccessories
            hasAlternatives
            id
            productId
            thumbnail
            packagingSize
            procuredProduct
            productCocontractor
            productName
            quantityUnit
            salesUnit
            sku
            supplierId
            supplierProductNumber
            isOnlineAvailable
            reachInfo
            relationType
            weightReference
            scoreCardIndex
            isUnqualifiedContractProduct

            supplierName
            quantity
            addedBy {
                contactId
                name
            }
            chargeNr
            positionText
            position
        }
    }
`;
