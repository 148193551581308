import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import FULL_USER_QUERY from '@ab-core/hooks/user/getFullUserQuery';

type QueryDataType = Pick<Query, 'getCustomerDataWithActiveAccount'>;
type OutgoingDataType = QueryDataType['getCustomerDataWithActiveAccount'];

const onError = getErrorLoggerFunction('getMyContact');

export const useGetMyContact = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(FULL_USER_QUERY, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getCustomerDataWithActiveAccount);
        }
    }, [data]);

    return {
        myContact: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
