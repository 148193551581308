import type { Mutation } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_CART_TEMPLATE } from './gql/deleteCartTemplate';

type MutationDataType = Pick<Mutation, 'deleteCartTemplate'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteCartTemplate');

export const useDeleteCartTemplate = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteCartTemplate, { loading, error, data }] = useMutation<MutationDataType>(DELETE_CART_TEMPLATE, {
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteCartTemplate,
        loading,
        response
    };
};
