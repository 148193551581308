export const isInValidDate = (isValid: Date) => isValid?.getTime() <= new Date().getTime();

export const stringIsInValidDate = (isValid?: string) => {
    const date = isValid ? new Date(isValid) : new Date();

    return isInValidDate(date);
};

export const isValidDateString = (dateString: string) => {
    const date = new Date(dateString);

    return !isNaN(date.getTime());
};
