import type { Colors } from '@ab-core/colors';
import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { Icon } from '@ab-core/icon';
import { getTestIdProp } from '@ab-core/testing';
import Text from '@ab-core/text';
import type { WithChildren } from '@ab-core/utils/types';
import React, { useEffect, useRef, useState, type FC } from 'react';
import { AccordionWrapper, Panel, Section } from './index.styled';

export type AccordionType = React.ComponentPropsWithoutRef<'div'> &
    WithChildren & {
        title: string;
        open?: boolean;
        disabled?: boolean;
        backgroundColor?: keyof typeof Colors;
        color?: keyof typeof Colors;
    };

const PADDING_HEIGHT = 16;

export const Accordion: FC<AccordionType> = (props) => {
    const { title, children, open = false, disabled = false, backgroundColor, color, onClick, ...rest } = props;

    const [isOpen, setIsOpen] = useState(open);
    const [panelHeight, setPanelHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    const sectionClassNames = getClassNames({ prefix: 'accordion-section', modifier: { disabled } });
    const panelClassNames = getClassNames({ prefix: 'accordion-panel', modifier: { open: isOpen } });

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (contentRef.current) {
            if (isOpen) {
                const scrollHeight = contentRef.current.clientHeight + 2 * PADDING_HEIGHT;
                setPanelHeight(scrollHeight);
            } else {
                setPanelHeight(0);
            }
        }
    }, [isOpen, contentRef?.current?.clientHeight]);

    const onSectionSelect: React.ComponentPropsWithoutRef<'div'>['onClick'] = (e) => {
        if (onClick) {
            onClick(e);
        }

        if (disabled) {
            return;
        }

        setIsOpen(!isOpen);
    };

    return (
        <AccordionWrapper {...rest}>
            <Section
                backgroundColor={backgroundColor}
                color={color}
                {...getTestIdProp(`accordion-${title}-section`)}
                className={sectionClassNames}
                onClick={onSectionSelect}
            >
                <Text color="inherit">{title}</Text>
                <Icon rotation={isOpen ? 0 : 180} name="ChevronUp" />
            </Section>

            <Panel className={panelClassNames} panelHeight={panelHeight}>
                <div ref={contentRef}>{children}</div>
            </Panel>
        </AccordionWrapper>
    );
};
