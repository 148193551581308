import { gql } from '@apollo/client';

export const UPDATE_SHOPPING_LIST_LINE_ITEM_V2 = gql`
    mutation UpdateShoppingListLineItemV2($lineItemInput: ShoppingListLineItemInput!, $id: String!) {
        updateShoppingListLineItemV2(lineItemInput: $lineItemInput, id: $id) {
            id
            lineItems {
                id
            }
        }
    }
`;
