import { gql } from '@apollo/client';

export const GET_REWARD_PRODUCTS = gql`
    query GetRewardProducts($offset: Int!, $limit: Int!) {
        getRewardProducts(offset: $offset, limit: $limit) {
            count
            offset
            results {
                isLimitedQuantity
                bonus {
                    isAbakus
                    isAbakusPlus
                    isPromotion
                    isTecselect
                    isTopProduct
                }
                categories {
                    custom {
                        customFieldsRaw {
                            name
                            value
                        }
                    }
                }
                weightReference
                weight
                thumbnail
                supplierProductNumber
                supplierProductLink
                supplierId
                slug
                sku
                scoreCardIndex
                salesUnit
                relationType
                relatedProducts {
                    spareParts
                    similarProducts
                    replacements
                    parts
                    necessarySelections
                    necessaryAccessories
                    baseProducts
                    alternativesEnventa
                    accessoriesProducts
                }
                reachInfo
                quantityUnit
                productName
                productFeatures {
                    version
                    unit
                    featureValues
                    featureValueScoped {
                        minValue
                        maxValue
                    }
                    featureName
                }
                productDocumentsDeha {
                    name
                    link
                }
                productCocontractor
                procuredProduct
                packagingSize
                isUnqualifiedContractProduct
                isOnlineAvailable
                images {
                    url
                    label
                }
                id
                hasAlternatives
                hasAccessories
                ean
                description
                customerArticleNumber
                customTariffNumber
            }
            total
        }
    }
`;
