import { gql } from '@apollo/client';
import { FRAGMENTS } from './../../_fragments';

export const GET_CART_BY_ID = gql`
    query GetCart($cartId: String!) {
        getCart(cartId: $cartId) {
            ...ABCartFieldsV2
            lineItemsV2 {
                ...ABLineItemFields
            }
            customLineItems {
                ean
                id
                name
                positionText
                addedBy {
                    contactId
                    name
                }
                quantity
                quantityUnit
                supplierName
                supplierProductNumber
            }
        }
    }
    ${FRAGMENTS.CART_OBJECT_V2}
    ${FRAGMENTS.LINE_ITEMS_DATA}
`;
