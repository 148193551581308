import { GlobalCss } from '@ab-core/abds-provider';
import type { MetaDataProps, WrapperProps } from '@ab-core/seo';
import { WrapPageElement } from '@ab-core/wrappageelement';
import type { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { GatsbyPageContainer } from './src/scopes/gatsbyPageContainer';
import { FeatureRoute, ProtectedRoute } from './src/scopes/home/components/protectedRoute';

type PageContextProps = {
    id?: string;
    protectedRoute?: boolean;
    redirectAfterLogin?: boolean;
    featureName?: string;
    requiredPermission?: string;
    hasNavigation?: boolean;
    hasProductComparison?: boolean;
    slug?: string;
    metaData?: WrapperProps['metaData'];
};

type AbWrapPageElementProps = GatsbyBrowser['wrapPageElement'];

export const abWrapPageElement: AbWrapPageElementProps = (gatsbyProps) => {
    const { props } = gatsbyProps;
    const pageContext: PageContextProps = props.pageContext;

    const protectedRoute = pageContext?.protectedRoute;

    const redirectAfterLogin = pageContext?.redirectAfterLogin;
    const featureName = pageContext?.featureName;
    const requiredPermission = pageContext?.requiredPermission;

    if (protectedRoute) {
        return (
            <ProtectedRoute
                redirectAfterLogin={redirectAfterLogin}
                requiredPermission={requiredPermission}
                featureName={featureName}
            >
                <Content {...gatsbyProps} featureName={featureName} />
            </ProtectedRoute>
        );
    }

    return <Content {...gatsbyProps} featureName={featureName} />;
};

const Content: AbWrapPageElementProps = (gatsbyProps) => {
    const { element, props } = gatsbyProps;

    const pageContext: PageContextProps = props.pageContext;
    const metaData: MetaDataProps = pageContext?.metaData;
    const hasNavigation = pageContext?.hasNavigation;

    const hasProductComparison =
        pageContext.hasProductComparison !== undefined ? pageContext.hasProductComparison : true;

    return (
        <FeatureRoute>
            <GlobalCss hasProductComparison={hasProductComparison}>
                <WrapPageElement {...props} metaData={metaData} hasNavigation={hasNavigation}>
                    <GatsbyPageContainer pathName={props.path} />
                    <>{element}</>
                </WrapPageElement>
            </GlobalCss>
        </FeatureRoute>
    );
};
