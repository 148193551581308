import type { CableSnap, DocumentWrapper, PositionPriceObject } from '@ab-core/graphql/dist';
import type { User } from '@ab-core/types/src';

export type MappedSearchResultDocument = {
    packagingSize?: string | number;
    id: string;
    sku: string;
    position?: number;
    productName: string;
    relationType?: string;
    ean: string;
    supplierId: string;
    image: string;
    procuredProduct: boolean;
    productCocontractor: boolean;
    salesUnit: number;
    quantityUnit: string;
    category: string;
    isTecselect?: boolean;
    isAbakus?: boolean;
    isAbakusPlus?: boolean;
    isUnqualifiedContractProduct: boolean;
    hasAccessories: boolean;
    hasAlternatives: boolean;
    alternativesLink?: string;
    accessoriesLink?: string;
    onlineAvailable?: boolean;
    promoProduct?: boolean;
    isPromotion?: boolean;
    topProduct?: boolean;
    reach?: boolean;
    cableSnap?: Array<CableSnap>;
    chargeNr?: string;
    quantity?: number;
    positionText?: string;
    priceObject?: PositionPriceObject;
    customerArticleNumber?: string;
    scoreCardIndex?: number;
    isPremiumProduct?: boolean;
    isVipProduct?: boolean;
};

const isUnqualifiedContractProduct = (user?: User, productCocontractor = false, productCocontractorValue?: string) => {
    if (productCocontractor) {
        if (user && user.cocontractors && productCocontractorValue) {
            return !user.cocontractors.includes(productCocontractorValue);
        }

        return true;
    }

    return false;
};
const productSearchMapper = (
    results: DocumentWrapper[] | undefined,
    user?: User
): MappedSearchResultDocument[] | undefined => {
    if (!results || !Array.isArray(results)) {
        return undefined;
    }

    return results.map(
        (result: DocumentWrapper): MappedSearchResultDocument => ({
            id: result.document?.id || '',
            sku: result.document?.sku || '',
            position: result.position || 1,
            packagingSize: result?.document?.packagingSize || '',
            productName: result.document?.productName || '',
            ean: result.document?.ean || '',
            supplierId: result.document?.supplierId || '',
            procuredProduct: result.document?.procuredProduct || false,
            productCocontractor: result.document?.productCocontractor || false,
            quantityUnit: result.document?.quantityUnit || '',
            category: result.document?.category || '',
            hasAccessories: result.document?.hasAccessories || false,
            hasAlternatives: result.document?.hasAlternatives || false,
            salesUnit: parseInt(result.document?.salesUnit || '1', 10),
            isTecselect: result.document?.bonus?.some((bonus) => bonus === 'tecselectBonus') || false,
            isAbakus: result.document?.bonus?.some((bonus) => bonus === 'abakusBonus') || false,
            isAbakusPlus: result.document?.bonus?.some((bonus) => bonus === 'abakusPlusBonus') || false,
            isUnqualifiedContractProduct: isUnqualifiedContractProduct(
                user,
                result.document?.productCocontractor,
                result.document?.productCocontractorValue
            ),
            image: result.document?.image || '',
            alternativesLink:
                result.document?.hasAlternatives && user ? `/produkt/${result.document?.sku}#alternativen` : undefined,
            accessoriesLink: result.document?.hasAccessories ? `/produkt/${result.document?.sku}#zubehoer` : undefined,
            promoProduct: Boolean(result.document?.bonus?.find((bonus) => bonus === 'promotion')),
            isVipProduct: Boolean(result.document?.bonus?.find((bonus) => bonus === 'vip')),
            isPremiumProduct: Boolean(result.document?.bonus?.find((bonus) => bonus === 'premium')),
            scoreCardIndex: result.document?.scoreCardIndex,
            isPromotion: !!result.document?.isPromotion,
            topProduct: !!result.document?.topProduct,
            cableSnap: result.document?.cableSnaps,
            customerArticleNumber: result.document?.customerArticleNumber
        })
    );
};

export default productSearchMapper;
