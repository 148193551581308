import { seeProjectPrice } from '@ab-core-functions/user-permission';
import type { Cart, CartProduct, Query, QueryCartPriceObjectArgs } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import { priceStore } from '@ab-core/store';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CART_PRICE_OBJECT } from './gql/cartPriceObject';

type QueryDataType = Pick<Query, 'cartPriceObject'>;
type OutgoingDataType = QueryDataType['cartPriceObject'];
type IncomingDataType = {
    skip?: boolean;
    projectId?: number;
    cart?: Cart;
};

const onError = getErrorLoggerFunction('cartPriceObject');

export const useCartPriceObjectPremium = (props: IncomingDataType) => {
    const { skip = false, projectId = 0, cart } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { user } = useAuth();
    const canSeeProjectPrice = seeProjectPrice();
    const myCredits = priceStore.getters.useCredits();
    const maxCredits = priceStore.getters.useMaxCredits();
    const coupon = priceStore.getters.useCoupon();

    const credits = myCredits > maxCredits ? maxCredits : myCredits;

    const checkedProjectId = canSeeProjectPrice ? projectId : 0;

    const products = cart?.lineItemsV2?.map((lineItem) => {
        const cartProduct: CartProduct = {
            productId: lineItem.sku,
            quantity: lineItem.quantity
        };

        if (lineItem.chargeNr) {
            cartProduct.chargeNr = parseInt(lineItem.chargeNr);
        }

        return cartProduct;
    });

    const includeShipping = String(cart?.shippingAddress) !== 'PICKUP';

    const isSkip = !products || !user || skip;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryCartPriceObjectArgs>(CART_PRICE_OBJECT, {
        variables: {
            coupon,
            credits,
            includeShipping,
            products,
            projectId: checkedProjectId
        },
        skip: isSkip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.cartPriceObject);
        }
    }, [data]);

    return {
        cartPrice: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
