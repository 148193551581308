import { CloudConfig, CloudinaryImage, URLConfig } from '@cloudinary/url-gen';

export enum CLOUDINARY_CLOUD_NAMES {
    ALEXANDER_BUERKLE = 'alexander-buerkle',
    ALEXANDER_BUERKLE_CLOUD_SERVICES = 'alexander-buerkle-cloud-services'
}

const replaceImageUrl = (src: string, removeStrings: Array<string>) => {
    let result = src;
    for (const str of removeStrings) {
        result = result.split(str).join('');
    }

    return result;
};

export const findCloudName = (src: string) => {
    if (src.includes(CLOUDINARY_CLOUD_NAMES.ALEXANDER_BUERKLE_CLOUD_SERVICES)) {
        return CLOUDINARY_CLOUD_NAMES.ALEXANDER_BUERKLE_CLOUD_SERVICES;
    }

    if (src.includes(CLOUDINARY_CLOUD_NAMES.ALEXANDER_BUERKLE)) {
        return CLOUDINARY_CLOUD_NAMES.ALEXANDER_BUERKLE;
    }

    return undefined;
};

export const getCloudinaryImage = (src: string, removeStrings: Array<string>): CloudinaryImage | undefined => {
    if (!findCloudName(src)) {
        return undefined;
    }

    const cloudName = findCloudName(src);
    const urlConfig = new URLConfig({ secure: true });
    const cloudConfig = new CloudConfig({ cloudName });
    const imageSrc = replaceImageUrl(decodeURIComponent(src), removeStrings);
    const cldImg = new CloudinaryImage(imageSrc, cloudConfig, urlConfig);

    return cldImg.quality('auto').format('webp');
};
