import { gql } from '@apollo/client';
import { CART_OBJECT_WITHOUT_LINE_ITEMS } from '../../fragments/cartFragmentWithOutLineItems';

export const CARTS = gql`
    query getMyCarts($sessionId: String) {
        getMyCarts(sessionId: $sessionId) {
            ...ABCartFieldsWithoutLineItems
        }
    }
    ${CART_OBJECT_WITHOUT_LINE_ITEMS}
`;
