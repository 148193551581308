import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import useText from '@ab-core/localization/src/useText';
import type { Plugins } from '@cloudinary/html/dist';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { withPrefix } from 'gatsby';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { StyledImage } from './styled';
import { getCloudinaryImage } from './utils';

enum OBJECT_FIT {
    FILL = 'fill',
    CONTAIN = 'contain',
    COVER = 'cover',
    NONE = 'none',
    SCALE_DOWN = 'scale-down'
}

enum RESPONSIVE_STEPS {
    XS = 200,
    SM = 600,
    MD = 900,
    LG = 1200,
    XL = 1800
}

export type ImageProps = React.ComponentPropsWithoutRef<'img'> & {
    objectFit?: keyof typeof OBJECT_FIT;
    responsiveSteps?: keyof typeof RESPONSIVE_STEPS;
};

const FALLBACK_IMAGE = withPrefix('/images/products/fallbackImage.webp');

export const Image: FC<ImageProps> = (props) => {
    const { src, objectFit, className, loading = 'lazy', responsiveSteps = 'XS', ...rest } = props;
    const [imageError, setImageError] = useState(false);
    const removeStringsText = useText('cloudinary.removeStrings');
    const removeStrings = removeStringsText ? removeStringsText.split('\n') : undefined;

    const srcProp = src ? src : FALLBACK_IMAGE;
    const objectFitProp = objectFit ? OBJECT_FIT[objectFit] : OBJECT_FIT.CONTAIN;
    const newClassName = getClassNames({
        className,
        prefix: 'image',
        modifier: { 'object-fit': objectFitProp }
    });

    const cldImg = useMemo(
        () => (srcProp && removeStrings ? getCloudinaryImage(srcProp, removeStrings) : undefined),
        [srcProp]
    );

    const handleImageError = () => {
        setImageError(true);
    };

    const isCloudinaryImage = cldImg && !imageError;

    const plugins: Plugins = [
        lazyload({ rootMargin: '10px', threshold: 0.1 }),
        responsive({ steps: RESPONSIVE_STEPS[responsiveSteps] })
    ];

    return (
        <StyledImage>
            {isCloudinaryImage && (
                <AdvancedImage
                    className={newClassName}
                    cldImg={cldImg}
                    plugins={plugins}
                    onError={handleImageError}
                    {...rest}
                />
            )}
            {!isCloudinaryImage && (
                <img src={imageError ? FALLBACK_IMAGE : srcProp} className={newClassName} loading={loading} {...rest} />
            )}
        </StyledImage>
    );
};
