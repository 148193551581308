import { countryCodes } from '@ab-core/country-codes';
import type { Customer, ShippingAddress } from '@ab-core/graphql/dist';
import type { Account, User } from './useAuth';

type Data = {
    getCustomerDataWithoutRightsAndActiveAccount?: Customer[];
    getCustomerData?: Customer[];
    getCustomerDataWithActiveAccount?: Customer[];
};

const getMappedAccounts = (data: Customer): Account[] | undefined =>
    data?.accounts?.map((account) => ({
        accountId: account.accountId || '',
        accountNumber: account.customerId || '',
        lock: account?.lock || 0,
        customerClassification: account.customerClassification || '',
        accountName: account.company || ''
    }));

const getActiveAccount = (data: Customer): Account | undefined => {
    if (data?.activeAccount) {
        return {
            accountId: data.activeAccount?.accountId,
            accountNumber: data.activeAccount?.customerId,
            lock: data.activeAccount?.lock,
            businessUnit: data.activeAccount?.businessUnit,
            hasForeignVatType: data.activeAccount?.hasForeignVatType,
            customerClassification: data.activeAccount?.customerClassification || '',
            accountName: data.activeAccount?.company || ''
        };
    }

    return undefined;
};

const getAddress = (data: Customer): ShippingAddress | undefined => {
    if (!data?.activeAccount?.shippingAddresses?.length) {
        return;
    }

    return data?.activeAccount?.shippingAddresses.find((shippingAddress) => shippingAddress.primaryAddress);
};

const getShippingCosts = (hasShippingCosts: boolean | undefined | null): boolean => {
    if (typeof hasShippingCosts === 'undefined') {
        return true;
    }

    if (hasShippingCosts === null) {
        return true;
    }

    return hasShippingCosts;
};

const mapShippingAddresses = (data: Customer, text: string): ShippingAddress[] | undefined => {
    if (!Array.isArray(data?.activeAccount?.shippingAddresses) || !data?.activeAccount?.shippingAddresses?.length) {
        return;
    }

    return data.activeAccount?.shippingAddresses?.map((address: ShippingAddress) => ({
        externalId: address?.externalId || '',
        id: address.id || '',
        primaryAddress: address.primaryAddress || false,
        name: (address.name === 'address.billingAddressName' ? text : address.name) || '',
        country: countryCodes.find((country) => country.id === address.country)?.name || address.country,
        streetName: address.streetName || '',
        postalCode: address.postalCode || '',
        city: address.city || '',
        addressExtraLineOne: address.addressExtraLineOne || '',
        addressExtraLineTwo: address.addressExtraLineTwo || '',
        addressExtraLineThree: address.addressExtraLineThree || '',
        defaultShippingInstructions: address.defaultShippingInstructions || ''
    }));
};
const userMapperBasics = (user: Customer, text: string): User => {
    const { contact, activeAccount } = user;

    return {
        contact,
        activeAccount: getActiveAccount(user),
        address: getAddress(user),
        deliveryAddresses: mapShippingAddresses(user, text),
        firstName: contact?.firstName || '',
        lastName: contact?.lastName || '',
        email: contact?.email || '',
        phone: contact?.phone || '',
        fax: contact?.fax || '',
        noSurchargeCutCost: activeAccount?.noSurchargeCut || false,
        cocontractors: activeAccount?.cocontractors || [],
        userName: contact?.userName || '',
        hasAbakus: activeAccount?.hasAbakus || false,
        collectCredits: activeAccount?.collectCredits || false,
        availableCredits: activeAccount?.collectCredits ? activeAccount?.availableCredits || 0 : undefined,
        hasForeignVat: activeAccount?.hasForeignVatType || false,
        hasShippingCosts: getShippingCosts(activeAccount?.hasShippingCosts),
        hasPromotionPrice: !activeAccount?.hasNoPromotionPrice,
        customerClassification: activeAccount?.customerClassification || '',
        responsibleSubsidiary: activeAccount?.responsibleSubsidiary || '',
        rewardShopRequestStatus: user?.activeAccount?.rewardShopRequestStatus || '',
        expireCredits: activeAccount?.expireCredits,
        outstandingCredits: activeAccount?.outstandingCredits,
        tecselectPoints: activeAccount?.tecselectPoints || 0,
        availableRewardPoints: activeAccount?.availableRewardPoints ?? 0,
        outstandingRewardPoints: activeAccount?.outstandingRewardPoints ?? 0
    };
};

const userMapper = (text: string, result?: Data): User | undefined => {
    if (!result?.getCustomerData || !Array.isArray(result?.getCustomerData)) {
        return;
    }

    const data = result?.getCustomerData[0];

    return {
        ...userMapperBasics(data, text),
        featureAccess: data?.contact?.featureAccess,
        accounts: getMappedAccounts(data)
    };
};

export const userMapperWithActiveAccount = (text: string, result?: Data): User | undefined => {
    if (!result?.getCustomerDataWithActiveAccount || !Array.isArray(result?.getCustomerDataWithActiveAccount)) {
        return;
    }

    const data = result?.getCustomerDataWithActiveAccount[0];

    return {
        ...userMapperBasics(data, text),
        featureAccess: data?.contact?.featureAccess
    };
};

export const userMapperWithoutRightsAndActiveAccount = (text: string, result?: Data): User | undefined => {
    if (
        !result?.getCustomerDataWithoutRightsAndActiveAccount ||
        !Array.isArray(result?.getCustomerDataWithoutRightsAndActiveAccount)
    ) {
        return;
    }

    const data = result?.getCustomerDataWithoutRightsAndActiveAccount[0];

    return {
        ...userMapperBasics(data, text)
    };
};

export default userMapper;
