import { gql } from '@apollo/client';

export const LINE_ITEMS_DATA = gql`
    fragment ABLineItemFields on LineItemProduct {
        bonus {
            isTecselect
            isAbakus
            isAbakusPlus
            isPromotion
            isTopProduct
            isVipProduct
            isPremiumProduct
        }
        customerArticleNumber
        ean
        hasAccessories
        hasAlternatives
        id
        thumbnail
        packagingSize
        procuredProduct
        productCocontractor
        productName
        quantityUnit
        salesUnit
        sku
        supplierId
        supplierProductNumber
        isOnlineAvailable
        reachInfo
        relationType
        weightReference
        scoreCardIndex
        isUnqualifiedContractProduct
        isLimitedQuantity
        productId
        supplierName
        quantity
        addedBy {
            contactId
            name
        }
        chargeNr
        positionText
        position
    }
`;
