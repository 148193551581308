import { Colors } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import { FormElementHeight } from '@ab-core/forms';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled, { css } from 'styled-components';

const font = css`
    font-weight: ${FontTypes.body.fontWeight};
    font-size: ${FontTypes.body.fontSize};
    letter-spacing: ${FontTypes.body.letterSpacing};
    text-transform: ${FontTypes.body.textTransform};
`;

export const Label = styled.label`
    position: absolute;
    color: ${Colors.gray70};
    top: 10px;
    left: 12px;
    pointer-events: none;
    transform: translateY(-16px);
    background-color: transparent;
    ${font}
    font-size: 0.85em;

    &.ab-dropdown-label-error {
        color: ${Colors.error};
    }
`;

export const IconWrapper = styled.div`
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;

export const DropdownContainer = styled.div`
    position: relative;

    .ab-dropdown-icon {
        transition: 0.1s;
        color: ${Colors.gray70};
        &.ab-dropdown-icon-error {
            color: ${Colors.error};
        }
    }
`;

export const Dropdown = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    ${font}
    width: 100%;
    padding: ${SPACING.SMALL_3};
    padding-right: calc(${SPACING.SMALL_3} + 12px);
    height: ${FormElementHeight.Normal};
    border: 1px solid ${Colors.gray70};
    background: ${Colors.white};
    border-radius: ${RADIUS.MINIMAL};
    color: ${Colors.gray70};
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    &:valid {
        color: ${Colors.black};
    }

    &.ab-dropdown-has-selected-item {
        color: ${Colors.black};
    }

    &.ab-dropdown-error {
        border: 1px solid ${Colors.error};
        color: ${Colors.error};
    }

    &.ab-dropdown-disabled {
        border: 1px solid ${Colors.gray70};
        background: ${Colors.gray15};
        cursor: default;
    }
`;

export const PseudoElementLine = styled.div`
    margin-left: -2px;
    margin-right: -2px;
    z-index: ${Z_INDEX.BELOW};
    position: absolute;
    height: 2px;
    top: 6px;
    right: 0;
    left: 0;
    background: ${Colors.white};
`;
