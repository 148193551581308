import type { Cart, Query } from '@ab-core/graphql/dist';
import CreateCart from '@ab-core/hooks/cartNew/createCart/createCart';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_MY_REWARD_SHOP_CART } from './gql/getMyRewardShopCart';

type QueryDataType = Pick<Query, 'getMyRewardShopCart'>;

const onError = getErrorLoggerFunction('getMyRewardShopCart');

const PREMIUM_CART_NAME = 'Prämienwarenkorb';

type PremiumCartProps = {
    skip?: boolean;
    createNewIfNotExist?: boolean;
};

export const usePremiumShopCart = (props: PremiumCartProps) => {
    const { skip = false, createNewIfNotExist = true } = props;

    const { createCart } = CreateCart(PREMIUM_CART_NAME);
    const createNewPremiumCart = async () => {
        await createCart({
            variables: {
                cartInput: {
                    custom: [
                        { name: 'name', value: PREMIUM_CART_NAME },
                        { name: 'private', value: 'true' },
                        { name: 'rewardShop', value: 'true' }
                    ]
                }
            }
        });
    };
    const [outgoingData, setOutgoingData] = useState<Cart>();
    const [createNewCart, setCreateCart] = useState(false);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_MY_REWARD_SHOP_CART, {
        skip,
        onError,
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        setCreateCart(false);

        if (data) {
            const premiumCart = data.getMyRewardShopCart?.filter((cart) => cart?.name === PREMIUM_CART_NAME);

            const isPremiumCartValue = premiumCart?.length ? premiumCart[0] : undefined;

            if (!isPremiumCartValue) {
                setCreateCart(true);

                return;
            }

            setOutgoingData(isPremiumCartValue);
        }
    }, [data]);

    const handleCreateNewCart = async () => {
        await createNewPremiumCart();
        setCreateCart(false);
        refetch();
    };
    useEffect(() => {
        if (createNewCart && createNewIfNotExist) {
            handleCreateNewCart();
        }
    }, [createNewCart]);

    return {
        premiumCart: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
