import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const AccountBox: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0612 2C7.82832 2 6.01185 3.92999 6.01185 6.30247C6.01185 7.64116 6.59635 8.83089 7.50102 9.61943C5.74971 9.90385 4.4 11.4976 4.4 13.4383V18.4753C4.4 18.765 4.62121 19 4.89383 19C5.16645 19 5.38765 18.765 5.38765 18.4753V13.4383C5.38765 11.876 6.58393 10.6049 8.05432 10.6049H12.0049C13.4753 10.6049 14.6716 11.876 14.6716 13.4383V18.4753C14.6716 18.765 14.8928 19 15.1654 19C15.4381 19 15.6593 18.765 15.6593 18.4753V13.4383C15.6593 11.5178 14.3365 9.93734 12.6112 9.62888C13.5224 8.84062 14.1106 7.6462 14.1106 6.30247C14.1106 3.92999 12.2942 2 10.0612 2ZM6.99951 6.30247C6.99951 4.50847 8.37277 3.04938 10.0612 3.04938C11.7497 3.04938 13.123 4.50847 13.123 6.30247C13.123 8.09646 11.7497 9.55556 10.0612 9.55556C8.37277 9.55556 6.99951 8.09646 6.99951 6.30247Z"
                fill={color}
            />
            <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke={color} />
        </SVG>
    );
};
