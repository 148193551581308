import { ROUTES } from '@ab-core/functions';
import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { Icon } from '@ab-core/icon';
import Text from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { OfferNavigationElement } from '../checkOffer';
import type { ContentItem } from '../types';
import { NavigationElementWrapper } from './index.styled';

type SingleNavigationElement = ContentItem & {
    pathName: string;
};

export const SingleNavigationElement: FC<SingleNavigationElement> = (props) => {
    const { icon, title, link, target, pathName } = props;

    const isActive = () => {
        if (target) {
            return pathName.includes(link);
        }

        return link.split('/')[1].includes(pathName.replace('/de-de', '').split('/')[1]);
    };
    const shopNavigationClass = getClassNames({
        prefix: 'shop-navigation',
        modifier: {
            isActive: isActive(),
            isActivePremium: pathName === `${ROUTES.PRAEMIEN_SHOP}/` && pathName?.includes(link)
        }
    });

    if (link === ROUTES.OFFERS) {
        return (
            <NavigationElementWrapper className={shopNavigationClass}>
                <OfferNavigationElement icon={icon} title={title} />
            </NavigationElementWrapper>
        );
    }

    return (
        <NavigationElementWrapper className={shopNavigationClass}>
            <Icon name={icon} />
            <Text>{title}</Text>
        </NavigationElementWrapper>
    );
};
