import type { MutationCreateMyCartArgs } from '@ab-core/graphql/dist';
import type { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CARTS } from '../getCarts/getMyCartsQuery';
import { RECEIVED_CARTS } from '../getCarts/getReceivedCartsQuery';
import { SHARED_CARTS } from '../getCarts/getSharedCartsQuery';
import type { CreateMyCartResponseType } from './createCartMutation';
import CREATE_MY_CART from './createCartMutation';

type CreateCartType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;
    response: CreateMyCartResponseType | undefined;
    createCart: (
        options?: MutationFunctionOptions<MutationCreateMyCartArgs>
    ) => Promise<FetchResult<CreateMyCartResponseType>>;
};
const CreateCart = (cartName: string, shareState?: string): CreateCartType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<CreateMyCartResponseType | undefined>(undefined);
    const [pending, setPending] = useState(false);
    const [createCart, { loading }] = useMutation<CreateMyCartResponseType, MutationCreateMyCartArgs>(CREATE_MY_CART, {
        variables: {
            cartInput: {
                custom: [
                    { name: 'name', value: cartName.trim() },
                    { name: 'shareState', value: shareState }
                ]
            }
        },

        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);

            if (res.createMyCart) {
                setSuccess(true);
            }

            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        },
        refetchQueries: [
            {
                query: CARTS
            },
            {
                query: RECEIVED_CARTS
            },
            {
                query: SHARED_CARTS
            }
        ]
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);

    return {
        createCart,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};

export default CreateCart;
