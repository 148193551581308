import { gql } from '@apollo/client';

export const GET_ACCOUNT_ADMIN_CONTACT = gql`
    query GetAccountAdminContact {
        getAccountAdminContact {
            firstName
            lastName
            email
        }
    }
`;
