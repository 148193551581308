import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const GET_CUSTOMER_DATA = gql`
    query GetCustomerData($force: Boolean) {
        getCustomerData(force: $force) {
            ...userFieldsV2
        }
    }
    ${FRAGMENTS.USER_DATA_V2}
`;
