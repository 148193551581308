import { gql } from '@apollo/client';
import { FRAGMENTS } from './../../_fragments';

export const GET_MY_REWARD_SHOP_CART = gql`
    query GetMyRewardShopCart($sessionId: String) {
        getMyRewardShopCart(sessionId: $sessionId) {
            ...ABCartFieldsV2
            lineItemsV2 {
                ...ABLineItemFields
            }
        }
    }
    ${FRAGMENTS.CART_OBJECT_V2}
    ${FRAGMENTS.LINE_ITEMS_DATA}
`;
