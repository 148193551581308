import type React from 'react';
import type { FC } from 'react';
import { useIsFeatureVisible } from './useIsFeatureVisible';

type FeatureToggleProps = {
    featureName?: string;
    children: React.ReactElement | null;
};

const FeatureToggle: FC<FeatureToggleProps> = ({ featureName, children }) => {
    const { isFeatureVisible } = useIsFeatureVisible();

    if (isFeatureVisible(featureName || '') || typeof featureName === undefined) {
        return children;
    }

    return null;
};

export default FeatureToggle;
