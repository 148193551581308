import { setSessionId } from '@ab-core-functions/tracking';
import { Colors } from '@ab-core/colors';
import type { FC } from 'react';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Head } from './head';
import type { MetaDataProps } from './metaHead';

export type WrapperProps = {
    slug: string;
    metaData?: MetaDataProps;
    children: React.ReactNode;
    backgroundColor?: string;
};

type BackgroundColor = {
    backgroundColor: string;
};
const PageBackground = createGlobalStyle<BackgroundColor>`
  body {
    background-color: ${({ backgroundColor }) => Colors[backgroundColor as keyof typeof Colors]};

    .oxomi-link {
        color: ${Colors.primary} !important;
        :hover {
            background-color: ${Colors.gray30} !important;
        }
    }
    .oxomi-btn {
        color: ${Colors.primary};
        :hover {
            background-color: ${Colors.gray30};
        }
    }
   
    #oxomi-add-to-basket {
      color:${Colors.primary};
      border: 2px solid red;
      background: ${
          Colors.primary
      } url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzNCQzlCRjk0QTM5MTFFNThEMDNDQ0M4OEVBRDlDNjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzNCQzlCRkE0QTM5MTFFNThEMDNDQ0M4OEVBRDlDNjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3M0JDOUJGNzRBMzkxMUU1OEQwM0NDQzg4RUFEOUM2NyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3M0JDOUJGODRBMzkxMUU1OEQwM0NDQzg4RUFEOUM2NyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuCGW0kAAADASURBVHjaYvj///8TILYHYgZSMCOQcGdgYKgD4moG4sEBkEZRIOMVA2mAEaQRxPgBxOzkaLwJxGqkaIR5ds9/0gADE9SExyT6kYEFSovikAcZeAuIr6PR4OiQA9JXgXgbEN+A+hdGf8XpSaBGTqgpE4H4DZEuXQALVQ0gjgZiXiI1FsD8CLLxNxB/A+J6KBsdgLxUCcT7wDxodNgjBbU/jvQ5FSr/E+xKqKAoEN8D4megwMKhMRCIvwPxOhAfIMAAX37s4FVnn+gAAAAASUVORK5CYII=) no-repeat center;;
        :hover {
            background-color: ${Colors.gray30};
        }
    }
    #oxomi-quantity {
      height: 32px;
    }
    .oxomi-infoplay-label {
      color: ${Colors.black}
    }

#oxomi-datasheet-deeplink-button-js{
 display: none !important;
}
.sci-btn{
  border: 2px solid ${Colors.primary} !important;
  :hover {
          background-color: ${Colors.primary} !important;
        }
}

.text-sirius-blue{
    color: ${Colors.primary} !important;
}

.sci-icon-product-cart-blue {
        filter: grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-30deg) saturate(600%) contrast(0.9) !important;
        :hover {
          filter: brightness(900%) grayscale(100%)  !important;
        }
       
    }
    #oxomi-dialog-content{
      background-color: ${Colors.white} !important;
    }
    #oxomi-dialog-header{
      background-color: ${Colors.gray30} !important;
    }

  }
`;

export const Wrapper: FC<WrapperProps> = (props) => {
    const { slug, backgroundColor = 'backgroundShade', metaData, children } = props;
    setSessionId();

    return (
        <>
            <PageBackground backgroundColor={backgroundColor} />
            <Head metaData={metaData} slug={slug} />
            {children}
        </>
    );
};
