import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Crown: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.17,12.1H3.83c-.31,0-.6-.2-.71-.49L.48,4.81c-.12-.31-.02-.67.24-.88.28-.21.64-.21.91-.01l3.46,2.51,2.25-3.85c.13-.23.38-.37.66-.37h0c.27,0,.52.14.66.37l2.25,3.85,3.46-2.51c.27-.2.64-.2.91,0,0,0,0,0,0,0,.27.21.37.57.24.88l-2.65,6.8c-.11.29-.4.49-.71.49ZM4.06,11.25h0s0,0,0,0ZM4,11.1h8.01l2.29-5.9-3.25,2.36c-.11.08-.26.11-.4.08-.14-.03-.26-.12-.33-.24l-2.33-3.98-2.33,3.98c-.07.12-.19.21-.33.24-.14.03-.28,0-.4-.08l-3.25-2.36,2.29,5.89ZM8.21,3.08h0s0,0,0,0Z"
                fill={color}
            />
            <path
                d="M12.17,13.96H3.83c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h8.33c.28,0,.5.22.5.5s-.22.5-.5.5Z"
                fill={color}
            />
        </SVG>
    );
};
