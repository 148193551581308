import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const CartPremium: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.35801 5C1.01608 5 0.740723 5.27667 0.740723 5.61728C0.740723 5.9579 1.01608 6.23457 1.35801 6.23457H2.68068H2.85075L2.88978 6.3907L6.52962 20.952C5.74735 21.2554 5.18517 22.0098 5.18517 22.9012C5.18517 24.0588 6.12637 25 7.28393 25C8.44047 25 9.3827 24.0588 9.3827 22.9012C9.3827 22.5907 9.31027 22.2995 9.18786 22.037H14.268C14.1458 22.2996 14.0741 22.5909 14.0741 22.9012C14.0741 24.0588 15.0153 25 16.1728 25C17.3294 25 18.2716 24.0588 18.2716 22.9012C18.2716 21.7436 17.3294 20.8025 16.1728 20.8025H7.76551L7.20995 18.5802H18.1481C18.431 18.5802 18.6779 18.3874 18.7469 18.1124L20.7223 10.211C20.7678 10.0275 20.7274 9.83121 20.6097 9.68129C20.4926 9.53217 20.3137 9.44444 20.1234 9.44444H6.97937H4.92601L4.45485 7.55936L3.93207 5.46771C3.86301 5.1927 3.61623 5 3.33332 5H1.35801ZM6.90618 17.3457L5.23475 10.679H8.63271H19.3322L17.6656 17.3457H17.5608H6.90618ZM6.41973 22.9012C6.41973 22.4242 6.80693 22.037 7.28393 22.037C7.76003 22.037 8.14813 22.4243 8.14813 22.9012C8.14813 23.3782 7.76003 23.7654 7.28393 23.7654C6.80693 23.7654 6.41973 23.3782 6.41973 22.9012ZM15.3086 22.9012C15.3086 22.4242 15.6958 22.037 16.1728 22.037C16.6489 22.037 17.037 22.4243 17.037 22.9012C17.037 23.3782 16.6489 23.7654 16.1728 23.7654C15.6958 23.7654 15.3086 23.3782 15.3086 22.9012Z"
                fill={color}
            />
            <g clipPath="url(#clip0_141_4952)">
                <path
                    d="M18.4561 6.79615L17.41 5.01116C17.1211 4.51826 17.2865 3.88449 17.7794 3.5956C17.9437 3.4993 18.1549 3.55443 18.2512 3.71873C18.3475 3.88304 18.2924 4.0943 18.1281 4.1906C17.9638 4.2869 17.9087 4.49816 18.005 4.66247L19.0511 6.44745C19.5326 7.26897 19.257 8.32526 18.4355 8.80675C17.7794 9.19126 16.9873 9.1368 16.3997 8.73232C16.5522 9.22568 16.801 9.7688 17.148 10.3607C17.2443 10.525 17.1891 10.7363 17.0248 10.8326L15.8348 11.53C15.6983 11.61 15.5249 11.5869 15.4142 11.4738L14.525 10.5662C14.2812 10.3173 13.8993 10.2663 13.5987 10.4425C13.2981 10.6187 13.156 10.9768 13.254 11.3112L13.6114 12.5304C13.6559 12.6823 13.5914 12.8449 13.4549 12.9249L12.2649 13.6224C12.1006 13.7187 11.8893 13.6636 11.793 13.4992L10.5725 11.4168C10.4539 11.2143 10.3608 11.004 10.2922 10.7895C10.0271 10.9996 9.95031 11.3781 10.1262 11.6783C10.2225 11.8426 10.1674 12.0539 10.0031 12.1502C9.8388 12.2465 9.62753 12.1914 9.53123 12.027C9.14603 11.3698 9.36655 10.5248 10.0238 10.1396L10.1533 10.0637C10.0785 8.97934 10.5781 7.89435 11.5343 7.26128C11.5335 7.25992 11.5327 7.25858 11.5319 7.25721L10.6601 5.76973C10.5638 5.60542 10.6189 5.39416 10.7832 5.29786L13.1632 3.90295C14.3133 3.22887 15.7921 3.61476 16.4662 4.76489L18.3545 7.98657C18.6293 7.6609 18.6839 7.18474 18.4561 6.79615ZM11.1675 11.068L12.2136 12.853L12.8739 12.4661L12.5922 11.5052C12.4052 10.8672 12.6763 10.1837 13.25 9.8475C13.8236 9.51131 14.5524 9.60864 15.0177 10.0836L15.7184 10.7989L16.3829 10.4094C15.8046 9.35277 15.5183 8.41726 15.5298 7.59975L15.23 7.08823C14.9975 6.69158 15.5925 6.34285 15.825 6.7395L16.4352 7.78075C16.7117 8.25243 17.2589 8.46704 17.764 8.34295L15.8713 5.11361C15.3898 4.29209 14.3335 4.01645 13.512 4.49794L11.4295 5.71849L12.1269 6.90847C12.5121 7.56568 13.3571 7.7862 14.0144 7.40101C14.1787 7.3047 14.3899 7.35983 14.4862 7.52414C14.5825 7.68845 14.5274 7.89971 14.3631 7.99601C13.5861 8.4514 12.6341 8.34238 11.9827 7.79307C10.8689 8.47943 10.5026 9.93363 11.1675 11.068Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_141_4952">
                    <rect
                        width="11.7242"
                        height="11.7242"
                        fill="white"
                        transform="translate(5.95679 5.92834) rotate(-30.3746)"
                    />
                </clipPath>
            </defs>
        </SVG>
    );
};
