import type { Query, QueryFastCaptureSearchArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { FAST_CAPTURE_SEARCH } from './gql/fastCaptureSearch';

type QueryDataType = Pick<Query, 'fastCaptureSearch'>;
type OutgoingDataType = QueryDataType['fastCaptureSearch'];
type IncomingDataType = {
    query: string;
    minimumQueryLength?: number;
    skip?: boolean;
};

const MINIMUM_SEARCH_QUERY_LENGTH = 3;

const onError = getErrorLoggerFunction('fastCaptureSearch');

export const useFastCaptureSearch = (props: IncomingDataType) => {
    const { query, minimumQueryLength = MINIMUM_SEARCH_QUERY_LENGTH, skip = false } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skipValue = skip || !query || query?.length < minimumQueryLength;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryFastCaptureSearchArgs>(FAST_CAPTURE_SEARCH, {
        onError,
        skip: skipValue,
        variables: {
            query
        }
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.fastCaptureSearch);
        }
    }, [data]);

    return {
        fastCaptureSearch: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
