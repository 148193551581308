import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const OFFERS = gql`
    query Offers {
        offers {
            id
            bindingPeriod
            project
            projectNumber
            offerNumber
            offerDate
            offerText
            contactPerson
            lastModifiedAt
            purchaser
            priceObject {
                value {
                    ...MoneyObjectFields
                }
            }
        }
    }
    ${FRAGMENTS.MONEY_OBJECT}
`;
