import { gql } from '@apollo/client';

export const FAST_CAPTURE_SEARCH_V2 = gql`
    query FastCaptureSearchV2($query: String!, $sid: String) {
        searchV2(query: $query, sid: $sid) {
            total
            results {
                sku
                productName
                salesUnit
                quantityUnit
                ean
            }
        }
    }
`;
