import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { SPACING } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import { createGlobalStyle, css } from 'styled-components';

function createMarginsAndPaddings() {
    let styles = '';

    for (const key in SPACING) {
        if (SPACING.hasOwnProperty(key)) {
            const value = key.toLowerCase().replace('_', '');
            styles += `
            .ma-${value} {
            margin: ${SPACING[key as keyof typeof SPACING]};
            }
          `;
            styles += `
            .mt-${value} {
            margin-top: ${SPACING[key as keyof typeof SPACING]};
          }
        `;
            styles += `
            .mb-${value} {
            margin-bottom: ${SPACING[key as keyof typeof SPACING]};   
          }
        `;
            styles += `
            .ml-${value} {
            margin-left: ${SPACING[key as keyof typeof SPACING]};   
          }
        `;
            styles += `
            .mr-${value} {
            margin-right: ${SPACING[key as keyof typeof SPACING]};
          }
      `;
            styles += `
            .mx-${value} {
            margin-left:  ${SPACING[key as keyof typeof SPACING]};
            margin-right:  ${SPACING[key as keyof typeof SPACING]};
          
            }
          `;
            styles += `
            .my-${value} {
            margin-top: ${SPACING[key as keyof typeof SPACING]} ;
            margin-bottom: ${SPACING[key as keyof typeof SPACING]} ;
          
            }
        `;
            styles += `
            .pa-${value} {
            padding: ${SPACING[key as keyof typeof SPACING]};  
            }
        `;
            styles += `
        .pt-${value} {
        padding-top: ${SPACING[key as keyof typeof SPACING]};  
        }
    `;
            styles += `
          .pb-${value} {
          padding-bottom: ${SPACING[key as keyof typeof SPACING]};  
          }
    `;
            styles += `
          .pl-${value} {
          padding-left: ${SPACING[key as keyof typeof SPACING]};  
          }
      `;
            styles += `
        .pr-${value} {
        padding-right: ${SPACING[key as keyof typeof SPACING]};  
        }
`;
            styles += `
          .px-${value} {
          padding-left:  ${SPACING[key as keyof typeof SPACING]};
          padding-right:  ${SPACING[key as keyof typeof SPACING]};
        
          }
        `;
            styles += `
          .py-${value} {
          padding-top: ${SPACING[key as keyof typeof SPACING]} ;
          padding-bottom: ${SPACING[key as keyof typeof SPACING]} ;
        
          }
          
    `;
            styles += `
    .gap-${value} {
   gap ${SPACING[key as keyof typeof SPACING]} ;
  
  
    }
    
`;
        }
    }

    return css`
        ${styles}
    `;
}
const GlobalStyle = createGlobalStyle`
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
* {
  -webkit-tap-highlight-color: rgba(255,0,0,0.2);
}

body * {
  font-family: 'TextaAlt', sans-serif;
}
#gatsby-focus-wrapper *  {
  font-family: 'TextaAlt', sans-serif;
}
#ab-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 0 auto;
  position: relative;
  padding-top: 56px;
  @media ${BreakpointsMinQuery.lg} {
    padding-top: 100px;
  }
 
}

u > * {
    text-decoration: underline;
}
a {
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;
  }
}

#gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}


.info__show-bottom{
    display:none!important;
}

[data-rsbs-overlay]{
  z-index: ${Z_INDEX.TOOLTIP}!important;
}
[data-tippy-root] {
  z-index: ${Z_INDEX.STICKY} !important;
       
}

[data-rsbs-backdrop]{
  z-index: ${Z_INDEX.TOOLTIP};
  pointer-events: none;
}

[data-rsbs-scroll]{
  margin-right: 4px;
  overflow-y: overlay;
  @media (min-width: 600px){
    margin-right: 10px;
  }
}

[data-rsbs-scroll]::-webkit-scrollbar{
  background-color: transparent;
  width: 10px;
}

[data-rsbs-scroll]::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 600px){
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

[data-rsbs-scroll]::-webkit-scrollbar-thumb {
  border-right: 2px solid ${Colors.primary};;
  border-radius: 2px;
}

#fc_frame { 
  right: 30px !important; 
  bottom: 75px !important; 
  z-index: ${Z_INDEX.FIXED} !important;
}

  ${createMarginsAndPaddings()};
.pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}
.text-line-through{
  text-decoration: line-through;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}


.text-nowrap{
  white-space: nowrap;
}
.text-word-break {
  word-break: break-word;
}
.text-word-keep-all {
  word-break: keep-all;
}
.text-truncate{
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.flex{
  display: flex;
}
.flex-justify-content-space-between  {
  display: flex;
  justify-content: space-between;
}
.flex-align-content-center  {
  display: flex;
  align-content: center;
}
.flex-direction-row{
  display: flex;
  flex-direction: row;
}
.flex-direction-column{
  display: flex;
  flex-direction: column;
}
.flex-justify-content-center{
  display: flex;
  justify-content: center;
}
.flex-justify-content-end{
  display: flex;
  justify-content: end;  
}
.flex-justify-content-space-around{
  display: flex;
  justify-content: space-around; 
}
.flex-justify-content-space-evenly{
  display: flex;
  justify-content: space-evenly; 
}
.flex-align-items{
  display: flex;
  align-items: center;
}

.flex-wrap-wrap{
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.full-height{
  height: 100%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

`;

export default GlobalStyle;
