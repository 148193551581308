enum SEARCH_VERSION {
    V1 = 'v1',
    V2 = 'v2'
}

export enum V2_HELPER_STATE {
    LOADING = 'loading',
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export const useIsSearchV2 = () => {
    const isSearchV2 = () => {
        const searchVersion = getSearchVersion();

        if (searchVersion === SEARCH_VERSION.V1) {
            return false;
        }

        return true;
    };

    return { isSearchV2 };
};

export const useIsSearchV2OrLoading = () => {
    const isSearchV2 = () => {
        const searchVersion = getSearchVersion();

        if (searchVersion === SEARCH_VERSION.V1) {
            return V2_HELPER_STATE.INACTIVE;
        }

        return V2_HELPER_STATE.ACTIVE;
    };

    return { isSearchV2 };
};

export const useGetSearchQueryString = () => {
    const { isSearchV2 } = useIsSearchV2();
    const isV2 = isSearchV2();

    if (isV2) {
        return 'query';
    }

    return 'q';
};

const TOGGLE_SEARCH_KEY = 'search_version';

export const toggleSearchVersion = () => {
    const searchVersion = getSearchVersion();

    if (searchVersion === SEARCH_VERSION.V2) {
        localStorage.setItem(TOGGLE_SEARCH_KEY, SEARCH_VERSION.V1);

        return;
    }

    localStorage.setItem(TOGGLE_SEARCH_KEY, SEARCH_VERSION.V2);
};

const getSearchVersion = () => {
    const toggleSearchItem = localStorage.getItem(TOGGLE_SEARCH_KEY);

    if (toggleSearchItem === SEARCH_VERSION.V1) {
        return SEARCH_VERSION.V1;
    }

    return SEARCH_VERSION.V2;
};
