import type { MappedProduct, SearchResult, SearchResultV2 } from '@ab-core/graphql/dist';
import type { MappedSearchResultDocument } from '@ab-core/hooks/search/mappers/productSearchMapper';
import productSearchMapper from '@ab-core/hooks/search/mappers/productSearchMapper';
import type { User } from '@ab-core/types/src';

type CustomSearchDataFields = {
    results: Array<MappedSearchResultDocument>;
    lastPage: number;
    searchResultCount: number;
};

export type SearchDataMapperType =
    | (Pick<SearchResult, 'currentPage' | 'didYouMean' | 'facets'> & CustomSearchDataFields)
    | undefined;

export const searchDataMapper = (data: SearchResult, user?: User): SearchDataMapperType => {
    const results = productSearchMapper(data.results, user);

    return {
        results: results || [],
        lastPage: data?.pageCount || 0,
        currentPage: data?.currentPage,
        searchResultCount: data?.total || 0,
        didYouMean: data?.didYouMean,
        facets: data?.facets
    };
};

const searchV2ProductsMapper = (products?: Array<MappedProduct>): Array<MappedProduct> | undefined => {
    return products?.map((product) => {
        return {
            ...product,
            image: product.image
        };
    });
};

export const searchV2DataMapper = (data: SearchResultV2): SearchResultV2 => {
    return { ...data, results: searchV2ProductsMapper(data.results) };
};
