import { getSessionId } from '@ab-core-functions/tracking';
import type { Query, QuerySearchV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { FAST_CAPTURE_SEARCH_V2 } from './gql/fastCaptureSearchV2';

type QueryDataType = Pick<Query, 'searchV2'>;
type OutgoingDataType = QueryDataType['searchV2'];

type IncomingDataType = {
    query?: string;
    skip?: boolean;
};

const onError = getErrorLoggerFunction('fastCaptureSearchV2');

const MINIMUM_SEARCH_QUERY_LENGTH = 2;

export const useFastCaptureSearchV2 = (props: IncomingDataType) => {
    const { query = '', skip = false } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skipValue = skip || query.length < MINIMUM_SEARCH_QUERY_LENGTH;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QuerySearchV2Args>(FAST_CAPTURE_SEARCH_V2, {
        onError,
        skip: skipValue,
        variables: { query, sid: getSessionId() }
    });

    useEffect(() => {
        if (data?.searchV2) {
            setOutgoingData(data.searchV2);
        }
    }, [data]);

    return {
        fastCaptureSearch: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
