import { gql } from '@apollo/client';

export const FAST_CAPTURE_SEARCH_LIST_V2 = gql`
    query FastCaptureSearchListV2($ids: [String!]!, $isSupplierProductNumber: Boolean, $sid: String) {
        fastCaptureSearchListV2(ids: $ids, isSupplierProductNumber: $isSupplierProductNumber, sid: $sid) {
            hits {
                id
                position
                sku
                productName
                ean
                supplierId
                supplierName
                productCocontractor
                image
                salesUnit
                packagingSize
                quantityUnit
                hasAccessories
                hasAlternatives
                isPromotion
                bonus
                customerArticleNumber
                scoreCardIndex
                fiveYearGuarantee
                supplierProductNumber
            }
            notFound
        }
    }
`;
