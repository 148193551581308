import { ROUTES } from '@ab-core/functions';
import { isShopApp } from '@ab-core/functions/isShopApp';
import type { ContentItem } from '../types';

type NavigationGroupType = {
    headline: string;
    content: Array<ContentItem>;
};

const adminRights = 'admin, supportAbAdmin';
const orderObject: NavigationGroupType = {
    headline: 'Kaufabwicklung',
    content: [
        { icon: 'Cart', title: 'Warenkörbe', link: ROUTES.CARTS, permissionName: 'hasSessionCarts' },
        {
            icon: 'Label',
            title: 'Angebote',
            link: ROUTES.OFFERS,
            permissionName: 'seeOffers',
            cypressId: 'go-to-offers-button'
        },
        {
            icon: 'Label',
            title: 'Leistungsverzeichnisse',
            link: ROUTES.SERVICE_DIRECTORY,
            featureName: 'serviceDirectory'
        },
        {
            icon: 'Orders',
            title: 'Belege',
            link: ROUTES.ORDERS,
            permissionName: 'seeOrders',
            cypressId: 'go-to-orders-button'
        },
        {
            icon: 'Bookmarks',
            title: 'Merklisten',
            link: ROUTES.SHOPPINGLISTS,
            permissionName: 'seeShoppinglists',
            cypressId: 'go-to-shopping-lists-button'
        },
        {
            icon: 'FastEntry',
            title: 'Schnellerfassung',
            link: ROUTES.FASTENTRY,
            permissionName: 'canSeeFastEntry',
            cypressId: 'go-to-fast-entry-button'
        },
        {
            icon: 'History',
            title: 'Zuletzt bestellte Artikel',
            link: ROUTES.LASTBOUGHTPRODUCTS,
            permissionName: 'canSeeLastBoughtProducts',
            cypressId: 'go-to-last-purchased-button'
        }
    ]
};

const specialsObject: NavigationGroupType = {
    headline: 'Angebote & Aktionen',
    content: [
        { icon: 'Discount', title: 'Aktionen', link: ROUTES.PROMOTION, cypressId: 'go-to-discount-button' },
        {
            featureName: 'praemienShop',
            icon: 'CartPremium',
            title: '125 Jahre Prämienshop',
            link: ROUTES.PRAEMIEN_SHOP,
            cypressId: 'go-to-premium-button'
        },
        {
            icon: 'Percent',
            title: 'Kabelschnäppchen',
            link: ROUTES.CABLEDEALMARKET,
            cypressId: 'go-to-cable-deal-market-button'
        }
    ]
};

const isApp = isShopApp();

const target = isApp ? '_self' : '_blank';

const serviceObject: NavigationGroupType = {
    headline: 'Service & Support',
    content: [
        {
            icon: 'Teach',
            title: 'Seminare & Webinare',
            link: 'https://akademie.alexander-buerkle.com',
            target
        },
        {
            icon: 'Factory',
            title: 'Serviceportal',
            link: 'https://alexander-buerkle.com/de-de/promotion/serviceportal',
            target
        },
        {
            icon: 'WindRose',
            title: 'Förderkompass',
            link: 'https://abuerkle.link/foerdkompass-onlineshop',
            target
        },
        {
            icon: 'Tune',
            title: 'Konfiguratoren',
            link: ROUTES.CONFIGURATORS,
            cypressId: 'go-to-configurators-button'
        },
        {
            icon: 'Search',
            title: 'Navigator',
            link: ROUTES.OXOMI_NAVIGATOR,
            target: '_blank'
        },
        {
            icon: 'Grid',
            title: 'Kataloge',
            link: ROUTES.OXOMICATALOGUES,
            target: '_blank',
            permissionName: 'canSeeCatalog',
            cypressId: 'go-to-catalogues-button'
        },
        {
            icon: 'QrCode',
            title: 'Kanbanetiketten',
            link: ROUTES.KANBAN,
            permissionName: 'canSeeKanban',
            cypressId: 'go-to-kanban-label-sets-button'
        },
        {
            icon: 'Partners',
            title: 'Vertragspartnerportal',
            link: '/promotion/vertragspartnerportal',
            target
        },
        {
            icon: 'Monitoring',
            title: 'Marktpreisentwicklung',
            link: '/promotion/marktpreisentwicklung',
            target
        },
        {
            icon: 'CableReel',
            title: 'Abholung Kabeltrommel',
            link: ROUTES.CABLEREEL,
            cypressId: 'go-to-cable-reel-button',
            target
        },
        {
            icon: 'ExternalLink',
            title: 'IT-Shop',
            link: 'https://itshop.alexander-buerkle.de/login',
            cypressId: 'go-to-it-shop-button',
            permissionName: 'hasItScope',
            target
        },
        {
            icon: 'Barcode',
            title: 'Wareneingang',
            link: ROUTES.RECEIPT,
            cypressId: 'go-to-receipts-button',
            permissionName: 'appIncomingGoods',
            onlyMobile: true
        }
    ]
};

const adminObject: NavigationGroupType = {
    headline: 'Benutzer & Account',
    content: [
        {
            icon: 'Account',
            title: 'Eigener Benutzer',
            link: `${ROUTES.OWN_USER}/uebersicht`,
            excludePermissionName: adminRights,
            cypressId: 'go-to-own-user-button'
        },
        {
            icon: 'Accounts',
            title: 'Benutzerverwaltung',
            link: ROUTES.USER_CONFIGURATOR,
            permissionName: adminRights,
            cypressId: 'go-to-user-management-button'
        },
        {
            icon: 'AccountBox',
            title: 'Accountverwaltung',
            link: `${ROUTES.ACCOUNT_CONFIGURATOR}/kostenstellen`,
            permissionName: adminRights,
            cypressId: 'go-to-account-management-button'
        }
    ]
};

export const navigation: Array<NavigationGroupType> = [orderObject, specialsObject, serviceObject, adminObject];
