import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
    mutation createMyOrderFromMyCart2($cartId: String!, $credits: Float, $coupon: CouponInput, $sessionId: String) {
        createMyOrder(cartId: $cartId, credits: $credits, coupon: $coupon, sessionId: $sessionId) {
            id
            orderNumber
            custom {
                customFieldsRaw {
                    name
                    value
                }
            }
        }
    }
`;
