import type { Contact, Customer, FeatureAccess, ShippingAddress } from '@ab-core/graphql/dist';
import { userMapperWithoutRightsAndActiveAccount } from '@ab-core/hooks/user/userMapper';
import useText from '@ab-core/localization/src/useText';
import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { USER_QUERY } from './getUserQuery';

type Data = {
    getCustomerData?: Customer[];
};

export type Account = {
    accountId: string;
    accountNumber: string;
    accountName?: string;
    lock?: number;
    businessUnit?: string;
    customerClassification: string;
    hasForeignVatType?: boolean;
};

export type User = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    fax: string;
    contact?: Contact;
    noSurchargeCutCost: boolean;
    cocontractors: Array<string>;
    address?: ShippingAddress;
    accounts?: Array<Account>;
    activeAccount?: Account;
    userName: string;
    availableRewardPoints?: number;
    outstandingRewardPoints?: number;
    hasAbakus: boolean;
    collectCredits: boolean;
    availableCredits?: number;
    expireCredits?: number;
    outstandingCredits?: number;
    hasShippingCosts: boolean;
    hasForeignVat?: boolean;
    hasPromotionPrice?: boolean;
    responsibleSubsidiary: string;
    rewardShopRequestStatus: string;
    deliveryAddresses?: Array<ShippingAddress>;
    featureAccess?: Array<FeatureAccess>;
    tecselectPoints?: number;
    customerClassification?: string;
};

type UseAuthType = {
    user?: User;
    userData?: User;
    authError?: ApolloError;
    userLoading?: boolean;
    success?: boolean;
    caching?: boolean;
    refetch?: () => void;
};

const useAuth = (caching = true): UseAuthType => {
    const [userData, setUserData] = useState<User | undefined>(undefined);

    const { data, error, loading, previousData, success, refetch } = userQuery(caching);
    const text = useText('address.billingAddressName');

    const user = userMapperWithoutRightsAndActiveAccount(text, data || previousData);

    useEffect(() => {
        setUserData(userMapperWithoutRightsAndActiveAccount(text, data || previousData));
    }, [data]);

    return {
        user,
        refetch,
        userData,
        success,
        userLoading: loading,
        authError: error
    };
};

export default useAuth;

const userQuery = (caching = true) => {
    const [success, setSuccess] = useState(false);
    const skip = typeof window === 'undefined' || !localStorage.getItem('isLoggedIn');
    const { data, error, previousData, loading, refetch } = useQuery<Data>(USER_QUERY, {
        skip,
        variables: {},
        fetchPolicy: caching ? 'cache-first' : 'no-cache',
        onCompleted: () => {
            setSuccess(true);
        }
    });

    return { data, error, previousData, loading, success, refetch };
};

export const isLoggedIn = () => {
    const { data, success } = userQuery();

    if (!success) {
        return false;
    }

    if (!data) {
        return false;
    }

    return true;
};
