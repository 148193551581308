import { gql } from '@apollo/client';

export const GET_CART_TEMPLATE = gql`
    query GetCartTemplate {
        getCartTemplate {
            accountId
            contactId
            cartInformationInput {
                fields {
                    name
                    value
                }
            }
            shippingInput {
                address {
                    id
                    externalId
                    name
                    streetName
                    addressExtraLineOne
                    addressExtraLineTwo
                    addressExtraLineThree
                    city
                    postalCode
                    country
                }
                shippingMethod
            }
        }
    }
`;
