import type { Mutation } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { REGISTER_REWARD_SHOP } from './gql/registerRewardShop';

type MutationDataType = Pick<Mutation, 'registerRewardShop'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('registerRewardShop');

type useRegisterRewardShopProps = {
    emailAddress: string;
    firstName: string;
    lastName: string;
    customerId: string;
};

export const useRegisterRewardShop = (props: useRegisterRewardShopProps) => {
    const { emailAddress, firstName, lastName, customerId } = props;
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [registerRewardShop, { loading, error, data }] = useMutation<MutationDataType>(REGISTER_REWARD_SHOP, {
        variables: {
            emailAddress,
            firstName,
            lastName,
            customerId
        },
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        registerRewardShop,
        loading,
        response
    };
};
