import { gql } from '@apollo/client';
import { MONEY_OBJECT } from './moneyObject';

export const CART_PRICE_OBJECT = gql`
    fragment CartPriceObjectFields on CartPriceObject {
        valueWithoutAdditional {
            ...MoneyObjectFields
        }
        vat {
            ...MoneyObjectFields
        }
        vats {
            key
            value {
                ...MoneyObjectFields
            }
        }
        credits {
            ...MoneyObjectFields
        }
        coupon {
            ...MoneyObjectFields
        }
        valueMaterialPriceSurchargesSum {
            ...MoneyObjectFields
        }
        valueSurchargeCutSum {
            ...MoneyObjectFields
        }
        value {
            ...MoneyObjectFields
        }
        shippingCosts {
            ...MoneyObjectFields
        }
        shippingCostInfo {
            outstandingValue {
                ...MoneyObjectFields
            }
            tresholdValue {
                ...MoneyObjectFields
            }
            serviceCost {
                ...MoneyObjectFields
            }
        }
        positionPriceObjects {
            basePrice {
                centAmount
            }
            surchargeCut {
                value {
                    ...MoneyObjectFields
                }
            }
        }
        valueWithTaxAndDiscounts {
            ...MoneyObjectFields
        }
    }
    ${MONEY_OBJECT}
`;
