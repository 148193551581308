import { gql } from '@apollo/client';

export const REGISTER_REWARD_SHOP = gql`
    mutation RegisterRewardShop($emailAddress: String!, $firstName: String!, $lastName: String!, $customerId: String!) {
        registerRewardShop(
            emailAddress: $emailAddress
            firstName: $firstName
            lastName: $lastName
            customerId: $customerId
        )
    }
`;
