import { useAuth } from '@ab-core/hooks';
import { graphql, useStaticQuery } from 'gatsby';

type ResourceType = {
    value: { value: string };
    key: string;
};

type NodesType = {
    name: string;
    resources: Array<ResourceType>;
};

type ContentfulResourceSetType = {
    allContentfulResourceSet: {
        nodes: Array<NodesType>;
    };
};

const GLOBAL_ACCESS = '*';

export enum FEATURE_STATE {
    LOADING = 'loading',
    ALLOWED = 'allowed',
    DENIED = 'denied'
}

export const useIsFeatureVisible = () => {
    const { allContentfulResourceSet } = useStaticQuery<ContentfulResourceSetType>(
        graphql`
            query resourceQuery {
                allContentfulResourceSet {
                    nodes {
                        name
                        resources {
                            value {
                                value
                            }
                            key
                        }
                    }
                }
            }
        `
    );
    const { nodes } = allContentfulResourceSet;

    const featureAccessListNode = nodes.filter((resource) => resource?.name === 'featureAccessList');

    const featureAccessList: { [key: string]: Array<string> } = {};

    for (const feature of featureAccessListNode[0].resources) {
        featureAccessList[feature.key.replace('featureAccessList.', '')] = feature?.value?.value
            ?.replaceAll(' ', '')
            .split(',');
    }

    const { user, userLoading } = useAuth();
    const accountNumber = user?.activeAccount?.accountNumber;

    const hasFeature = (featureName: string) => {
        return featureAccessList[featureName]?.some((access) => {
            if (access === GLOBAL_ACCESS) {
                return true;
            }

            return access === accountNumber;
        });
    };

    const isFeatureVisible = (featureName: string, isSite = false): boolean => {
        if (!featureName) {
            return true;
        }

        if (userLoading) {
            return !!isSite;
        }

        if (!accountNumber) {
            return false;
        }

        if (featureName) {
            return hasFeature(featureName);
        }

        return true;
    };

    const isFeatureVisibleOrLoading = (featureName: string): FEATURE_STATE => {
        if (userLoading) {
            return FEATURE_STATE.LOADING;
        }

        if (!accountNumber) {
            return FEATURE_STATE.DENIED;
        }

        const accountHasFeature = hasFeature(featureName);

        if (accountHasFeature) {
            return FEATURE_STATE.ALLOWED;
        }

        return FEATURE_STATE.DENIED;
    };

    return { isFeatureVisible, isFeatureVisibleOrLoading };
};
