import type { Mutation, MutationDeleteShoppingListLineItemV2Args } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_SHOPPING_LIST_LINE_ITEM_V2 } from './gql/deleteShoppingListLineItemV2';
import { SHOPPING_LIST_V2 } from './gql/shoppingListV2';

type MutationDataType = Pick<Mutation, 'deleteShoppingListLineItemV2'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteShoppingListLineItemV2');

const refetchQueries = [SHOPPING_LIST_V2];

export const useDeleteShoppingListLineItemV2 = (variables: MutationDeleteShoppingListLineItemV2Args) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteShoppingListLineItem, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationDeleteShoppingListLineItemV2Args
    >(DELETE_SHOPPING_LIST_LINE_ITEM_V2, {
        variables,
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteShoppingListLineItem,
        loading,
        response
    };
};
