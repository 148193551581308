import { gql } from '@apollo/client';

export const DELETE_SHOPPING_LIST_LINE_ITEM_V2 = gql`
    mutation DeleteShoppingListLineItemV2($id: String!, $lineItemId: String!) {
        deleteShoppingListLineItemV2(id: $id, lineItemId: $lineItemId) {
            id
            lineItems {
                id
            }
        }
    }
`;
