import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_ACCOUNT_ADMIN_CONTACT } from './gql/getAccountAdminContact';

type QueryDataType = Pick<Query, 'getAccountAdminContact'>;
type OutgoingDataType = QueryDataType['getAccountAdminContact'];

const onError = getErrorLoggerFunction('getAccountAdminContact');

export const useGetAccountAdminContact = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_ACCOUNT_ADMIN_CONTACT, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getAccountAdminContact);
        }
    }, [data]);

    return {
        accountAdmin: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
