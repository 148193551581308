import { useUserIsApproved } from '@ab-core-functions/reward-shop';
import { ROUTES } from '@ab-core/functions';
import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { useAuth } from '@ab-core/hooks';
import { usePremiumShopCart } from '@ab-core/hooks-v2';
import getActiveCart from '@ab-core/hooks/cartNew/activeCart/useActiveCart';
import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import { Link, LINK_VARIANT } from '@ab-core/link';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { CartCounter, CartCounterWhite } from './styled';

type CartProps = {
    pathname: string;
};

export const Cart: FC<CartProps> = (props) => {
    const { pathname } = props;
    const { user } = useAuth();
    const userIsApproved = useUserIsApproved();
    const skip = !user;
    const [isPremiumCart, setIsPremiumCart] = useState(false);
    const [cartCounterColor, setCartCounterColor] = useState('primary');
    const [cartIcon, setCartIcon] = useState<IconProps['name']>('Cart');
    const [cartLink, setCartLink] = useState(ROUTES.CART);
    const [cartCounter, setCartCounter] = useState(0);

    const { cartCount } = getActiveCart(skip || (isPremiumCart && userIsApproved));
    const { premiumCart } = usePremiumShopCart({ skip: !isPremiumCart });

    const colorClass = getClassNames({
        prefix: 'cart-counter',
        modifier: { color: cartCounterColor }
    });

    useEffect(() => {
        setIsPremiumCart(pathname.includes(ROUTES.PRAEMIEN_SHOP));
    }, [pathname]);

    useEffect(() => {
        if ((!skip && !isPremiumCart) || !userIsApproved) {
            setCartCounter(cartCount || 0);
            setCartCounterColor('primary');
            setCartIcon('Cart');
            setCartLink(ROUTES.CART);
        } else {
            setCartCounter(premiumCart?.lineItemsCount || 0);
            setCartCounterColor('gold');
            setCartIcon('CartPremium');
            setCartLink(ROUTES.PRAEMIEN_SHOP_CART);
        }
    }, [isPremiumCart, skip, premiumCart, cartCount]);

    return (
        <>
            {user ? (
                <Link variant={LINK_VARIANT.LINK_ONLY} to={cartLink}>
                    <div className="flex-direction-column flex-align-items">
                        <div className="flex mx-small5 mt-small4 gap-small2" {...getTestIdProp('go-to-cart-button')}>
                            <Icon name={cartIcon} color="black" />
                            {cartCounter !== 0 && (
                                <CartCounterWhite>
                                    <CartCounter className={colorClass}>{cartCounter}</CartCounter>
                                </CartCounterWhite>
                            )}
                        </div>
                    </div>
                </Link>
            ) : (
                <div className="flex-direction-column flex-align-items px-small4 mt-small2 py-small2">
                    <Icon name="Cart" color="gray50" />
                </div>
            )}
        </>
    );
};
