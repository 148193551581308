import { gql } from '@apollo/client';

export const CART_OBJECT_WITHOUT_LINE_ITEMS = gql`
    fragment ABCartFieldsWithoutLineItems on Cart {
        id
        version
        name
        isActiveCart
        orderText
        costCenter
        singleInv
        priceDN
        sentFrom
        shippingAddress {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
        }
        deliveryDate
        lastModifiedAt
        completeDelivery
        shippingInstructions
        displayShippingMethod
        cartProjectNr
        shareState
        createdBy {
            contactId
            name
        }
        shippingInfo {
            shippingMethod {
                key
            }
        }
        custom {
            customFieldsRaw {
                name
                value
            }
        }
        lineItemsCount
    }
`;
