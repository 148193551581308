import { useAuth } from '@ab-core/hooks';

export enum REQUEST_STATUS_STATE {
    IN_APPROVAL = 'In Approval',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    NOT_INTERACTED = '',

    LOADING = 'Loading',
    NOT_LOGGED_IN = 'Not logged in'
}

export const useGetRequestState = (): REQUEST_STATUS_STATE => {
    const { user, userLoading } = useAuth();

    if (userLoading) {
        return REQUEST_STATUS_STATE.LOADING;
    }

    if (!user) {
        return REQUEST_STATUS_STATE.NOT_LOGGED_IN;
    }

    const rewardShopRequestStatus = user.rewardShopRequestStatus;

    switch (rewardShopRequestStatus) {
        case REQUEST_STATUS_STATE.APPROVED:
            return REQUEST_STATUS_STATE.APPROVED;
        case REQUEST_STATUS_STATE.IN_APPROVAL:
            return REQUEST_STATUS_STATE.IN_APPROVAL;
        case REQUEST_STATUS_STATE.NOT_INTERACTED:
            return REQUEST_STATUS_STATE.NOT_INTERACTED;

        default:
            return REQUEST_STATUS_STATE.REJECTED;
    }
};

export const useUserIsApproved = () => {
    return useGetRequestState() === REQUEST_STATUS_STATE.APPROVED;
};
