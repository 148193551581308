import { gql } from '@apollo/client';

export const ACCOUNT_DATA_V2 = gql`
    fragment accountFieldsV2 on Account {
        accountId
        company
        customerId
        customerClassification
        noSurchargeCut
        cocontractors
        lock
        businessUnit
        hasAbakus
        tecselectPoints
        isGhostAccess
        collectCredits
        outstandingCredits
        expireCredits
        availableCredits
        hasShippingCosts
        hasForeignVatType
        hasNoPromotionPrice
        responsibleSubsidiary
        rewardShopRequestStatus
        shippingAddresses {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
            defaultShippingInstructions
        }
    }
`;
