import type { Mutation, MutationUpdateShoppingListLineItemV2Args } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SHOPPING_LIST_V2 } from './gql/shoppingListV2';
import { UPDATE_SHOPPING_LIST_LINE_ITEM_V2 } from './gql/updateShoppingListLineItemV2';

type MutationDataType = Pick<Mutation, 'updateShoppingListLineItemV2'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateShoppingListLineItemV2');

const refetchQueries = [SHOPPING_LIST_V2];

export const useUpdateShoppingListLineItemV2 = (id: MutationUpdateShoppingListLineItemV2Args['id']) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateShoppingListLineItem, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpdateShoppingListLineItemV2Args
    >(UPDATE_SHOPPING_LIST_LINE_ITEM_V2, {
        refetchQueries,
        onError
    });

    const outgoingUpdateShoppingListLineItem = useCallback(
        (lineItemInput: MutationUpdateShoppingListLineItemV2Args['lineItemInput']) => {
            updateShoppingListLineItem({ variables: { id, lineItemInput } });
        },
        [updateShoppingListLineItem]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateShoppingListLineItem: outgoingUpdateShoppingListLineItem,
        loading,
        response
    };
};
