import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Accounts: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.54975 0C4.73551 0 3.25963 1.70294 3.25963 3.7963C3.25963 4.9775 3.73454 6.02725 4.46958 6.72302C3.04664 6.97399 1.95 8.38028 1.95 10.0926V14.537C1.95 14.7926 2.12973 15 2.35124 15C2.57274 15 2.75247 14.7926 2.75247 14.537V10.0926C2.75247 8.7141 3.72444 7.59259 4.91914 7.59259H6.69287C6.8081 7.22421 6.97472 6.88084 7.18355 6.57231C6.98114 6.63387 6.76871 6.66667 6.54975 6.66667C5.17788 6.66667 4.0621 5.37923 4.0621 3.7963C4.0621 2.21336 5.17788 0.925926 6.54975 0.925926C7.92163 0.925926 9.03741 2.21336 9.03741 3.7963C9.03741 4.34853 8.90161 4.86479 8.66652 5.30298C8.95773 5.16974 9.26886 5.07713 9.59367 5.03183C9.61638 5.02824 9.63916 5.02495 9.66203 5.02196C9.77718 4.63787 9.83988 4.22572 9.83988 3.7963C9.83988 1.70294 8.364 0 6.54975 0ZM10.0536 5L10.0252 5.00012C10.0346 5.00004 10.0441 5 10.0536 5ZM10.4377 5.02216C10.7862 5.06258 11.1196 5.15735 11.4305 5.29836C11.1969 4.86117 11.0621 4.34659 11.0621 3.7963C11.0621 2.21336 12.1779 0.925926 13.5498 0.925926C14.9216 0.925926 16.0374 2.21336 16.0374 3.7963C16.0374 5.37923 14.9216 6.66667 13.5498 6.66667C13.3343 6.66667 13.1251 6.6349 12.9256 6.57521C13.1335 6.88299 13.2994 7.22536 13.4143 7.59259H15.129C16.3237 7.59259 17.2957 8.7141 17.2957 10.0926V14.537C17.2957 14.7926 17.4754 15 17.6969 15C17.9184 15 18.0981 14.7926 18.0981 14.537V10.0926C18.0981 8.39808 17.0234 7.00353 15.6216 6.73137C16.3619 6.03584 16.8399 4.98194 16.8399 3.7963C16.8399 1.70294 15.364 0 13.5498 0C11.7355 0 10.2596 1.70294 10.2596 3.7963C10.2596 4.22587 10.3224 4.63806 10.4377 5.02216Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0536 5C8.09978 5 6.51037 6.70294 6.51037 8.7963C6.51037 9.9775 7.02181 11.0273 7.81339 11.723C6.281 11.974 5.1 13.3803 5.1 15.0926V19.537C5.1 19.7926 5.29356 20 5.5321 20C5.77064 20 5.9642 19.7926 5.9642 19.537V15.0926C5.9642 13.7141 7.01094 12.5926 8.29753 12.5926H11.7543C13.0409 12.5926 14.0877 13.7141 14.0877 15.0926V19.537C14.0877 19.7926 14.2812 20 14.5198 20C14.7583 20 14.9519 19.7926 14.9519 19.537V15.0926C14.9519 13.3981 13.7944 12.0035 12.2848 11.7314C13.0821 11.0358 13.5968 9.98194 13.5968 8.7963C13.5968 6.70294 12.0074 5 10.0536 5ZM7.37457 8.7963C7.37457 7.21336 8.57617 5.92593 10.0536 5.92593C11.531 5.92593 12.7326 7.21336 12.7326 8.7963C12.7326 10.3792 11.531 11.6667 10.0536 11.6667C8.57617 11.6667 7.37457 10.3792 7.37457 8.7963Z"
                fill={color}
            />
        </SVG>
    );
};
