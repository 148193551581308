import type { Mutation, MutationCreateOrUpdateCartTemplateArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_OR_UPDATE_CART_TEMPLATE } from './gql/createOrUpdateCartTemplate';

type MutationDataType = Pick<Mutation, 'createOrUpdateCartTemplate'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createOrUpdateCartTemplate');

export const useCreateOrUpdateCartTemplate = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createOrUpdateCartTemplate, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateOrUpdateCartTemplateArgs
    >(CREATE_OR_UPDATE_CART_TEMPLATE, {
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createOrUpdateCartTemplate,
        loading,
        response
    };
};
