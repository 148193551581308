import { gql } from '@apollo/client';

export const CREATE_ORDER_REWARD = gql`
    mutation createMyRewardOrder($cartId: String!) {
        createMyRewardOrder(cartId: $cartId) {
            id
            custom {
                customFieldsRaw {
                    name
                    value
                }
            }
            orderNumber
        }
    }
`;
