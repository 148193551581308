export type SearchParameter = {
    productQueryString: string;
    hitsPerPage?: string;
    page?: string;
    sort?: string;
    filter: Array<string>;
};

export const getSearchFilterParameterFromUrl = (url?: string): SearchParameter => {
    const urlSearchParams = new URLSearchParams(url || location?.search);

    const searchParameter: SearchParameter = { productQueryString: '', filter: [], sort: '' };
    urlSearchParams.forEach((value, key) => {
        switch (key) {
            case 'q':
                searchParameter.productQueryString = value;
                break;
            case 'query':
                searchParameter.productQueryString = value;
                break;
            case 'filter':
                searchParameter.filter.push(value);
                break;
            case 'page':
                searchParameter.page = value;
                break;
            case 'hitsPerPage':
                searchParameter.hitsPerPage = value;
                break;
            case 'sort':
                searchParameter.sort = value;
                break;
        }
    });

    return searchParameter;
};
