import { gql } from '@apollo/client';
import { CART_OBJECT_WITHOUT_LINE_ITEMS } from '../../fragments/cartFragmentWithOutLineItems';

export const RECEIVED_CARTS = gql`
    query getCarts {
        getCarts {
            ...ABCartFieldsWithoutLineItems
        }
    }
    ${CART_OBJECT_WITHOUT_LINE_ITEMS}
`;
